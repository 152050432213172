import axios from 'axios'

export class ApiService {
  token: string | null = null

  constructor() {
    const token = localStorage.getItem('t13-token')

    if (token) {
      this.token = token
    }
  }

  setToken (newToken: string | null) {
    this.token = newToken
  }

  async baseApiRequest (type: 'get' | 'post' | 'patch' | 'delete', url: string, params: any = null, contentType: any = null): Promise<{ data: any, error: any }> {
    let result = null
    let error = null

    try {
      const options = {
        withCredentials: true,
        headers: {
          "Authorization": this.token ? `jwt ${this.token}` : undefined,
          "Content-Type": contentType ? contentType : "application/json",
        }
      }

      if (type === 'get' || type === 'delete') {
        result = await axios[type](process.env.REACT_APP_API_ENDPOINT + url, options)
      } else {
        result = await axios[type](process.env.REACT_APP_API_ENDPOINT + url, params, options)
      }
    } catch (err) {
      error = err
    }

    return {
      data: result?.data,
      error,
    }
  }
}
