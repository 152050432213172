import React, { FC, Suspense } from "react"
import { Routes, Route } from "react-router-dom"
import Spinner from '../components/Spinner/Spinner'

const MainPage = React.lazy(() => import('pages/main/MainPage'))
const LoginPage = React.lazy(() => import('pages/login/LoginPage'))
const RegistrationPage = React.lazy(() => import('pages/registration/RegistrationPage'))
const AboutPage = React.lazy(() => import('pages/about/AboutPage'))
const EventPage = React.lazy(() => import('pages/event/EventPage'))
const EventsPage = React.lazy(() => import('pages/events/EventsPage'))
const VideoPage = React.lazy(() => import('pages/videos/VideosPage'))
const TravelsPage = React.lazy(() => import('pages/travels/TravelsPage'))
const TravelPage = React.lazy(() => import('pages/travel/TravelPage'))
const TeamPage = React.lazy(() => import('pages/team/TeamPage'))
const ProfilePage = React.lazy(() => import('pages/profile/ProfilePage'))
const Page404 = React.lazy(() => import('pages/Page404/Page404'))
const ProfileEditPage = React.lazy(() => import('pages/profile-edit/ProfileEditPage'))
const ProfileSettingsPage = React.lazy(() => import('pages/profile-settings/ProfileSettingsPage'))
const DtpPage = React.lazy(() => import('pages/dtp/DtpPage'))
const DtpListPage = React.lazy(() => import('pages/dtp/DtpListPage'))
const PolicyPage = React.lazy(() => import('pages/policy/PolicyPage'))
const InfrastructurePage = React.lazy(() => import('pages/infrastructure/InfrastructurePage'))
const UserPage = React.lazy(() => import('pages/user/UserPage'))
const RouteEvents = React.lazy(() => import('pages/route-events/RouteEvents'))

const AppRoutes: FC = () => (
  <Suspense fallback={<Spinner />}>
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/registration" element={<RegistrationPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/videos" element={<VideoPage />} />
      <Route path="/events" element={<EventsPage />}>
        <Route path=":status" element={<EventsPage />} />
      </Route>
      <Route path="/team" element={<TeamPage />} />
      <Route path="/event" element={<EventPage />}>
        <Route path=":id" element={<EventPage />} />
      </Route>

      <Route path="/profile/edit" element={<ProfileEditPage />} />
      <Route path="/profile/settings" element={<ProfileSettingsPage />} />
      <Route path="/profile" element={<ProfilePage />}>
        <Route path=":currentTab" element={<ProfilePage />} />
      </Route>

      <Route path="/route-events" element={<RouteEvents />} />

      <Route path="/user" element={<UserPage />}>
        <Route path=":id" element={<UserPage />} />
      </Route>

      <Route path="/travels" element={<TravelsPage />} />
      <Route path="/travel" element={<TravelPage />}>
        <Route path=":id" element={<TravelPage />} />
      </Route>
      <Route path="/dtp/:id" element={<DtpPage />} />
      <Route path="/dtp" element={<DtpListPage />} />
      <Route path="/policy" element={<PolicyPage />} />
      <Route path="/infrastructure" element={<InfrastructurePage />} />

      <Route path="*" element={<Page404 />} />
    </Routes>
  </Suspense>
)

export default AppRoutes
